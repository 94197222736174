<template>

<app-page>

	<app-page-head title="Content Blocks" :count="list.length">

		<app-page-head-search v-model="searchText" :count="list.length" />
		<app-page-head-action route="Text" :params="{ text: 'new' }" icon="create" tooltip="Add block" />
		<app-page-head-action v-on:click="onDeleteClick" :danger="true" :disabled="!selected.length" :count="selected.length" icon="delete" tooltip="Delete selected item(s)" />
		
	</app-page-head>

	<app-page-content :is-empty="!list.length" empty-message="No content blocks found.">

		<com-item v-for="item in list" :key="item.id" :item="item" :highlight="searchText" :selected="isSelected(item.id)" v-on:shiftclick="onSelectClick(item.id)" />

	</app-page-content>

	<app-page-foot />

</app-page>

</template>

<script>

import mixList from '@/mixin/list'

export default {

	mixins: [mixList],

	components: {
		'com-item': () => import('./texts/Item')
	},

	data: function() {

		return {
			store: 'texts'
		}

	},

	methods: {

		search: function(value) {

			return this.$util.search(value.title, this.searchText) 

		}

	}

}

</script>

<style scoped>

</style>
